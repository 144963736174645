import React, { useContext } from "react"
import styled from 'styled-components'
import MainHeading from '@typo/MainHeading'
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import headerImage from '@images/articles/erotic-audio-stories/blog-header.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "@components/Seo";
import Header from "@menus/header"
import { FirebaseContext } from '@components/Firebase'

const ArticleWrapper = styled.div`
  display:flex;
  justify-content: center;
  padding-top:90px;
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const ArticleHeaderImage =  styled.img`
/* padding-top:30px; */
`

const ContentPaddingWrapper = styled.div`
  max-width:900px;
  padding: 0px 5% 100px 5%;
`

const FirstText =  styled(Text)`
 &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
  margin-bottom:50px;

`

const MetaData =  styled.p`
  font-family:${props => props.title ? '' : props.theme.mainFontParagraph};
  font-size:0.8rem;
  line-height:0.5;
`
const ListItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-bottom:50px;
  align-items: center;

`

const ItemImageWrapper = styled.div`
  width:100%;
  @media (min-width: 768px) {
    width:100%;
  }
`

const ItemTextColumn = styled.div`

  
`
const ItemText = styled.div`
  font-family: ${props => props.theme.mainFontParagraph},'Arial';
  line-height: 1.6;
  h4 {
    margin: 0px;
    font-size: 1.4rem;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }
`

const ItemCta = styled(Text)`
  font-family:'Poppins';
`
const ItemMetaInfo = styled(Text)`
  line-height:1;
`
const SubHeadline = styled(Text)`
  padding-bottom:10px;
  font-style:italic;
`

const EroticAudio = styled.span`
  font-size:14px; 
  font-family:'Poppins';
  padding-left:3px;
  display: block;
`

const ItemHeadline = styled.h2`
  font-size: 2.5rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`


const content = {
  "holi-heat":{
    "subtitle":"Hindi Audio Sex Story:",
    "text":"In this audio sex story written by The Secret Submissive, a mischievous couple gets carried away in the excitement of Holi celebrations after weeks of being apart. The tender reunion kiss they share turns into a desire neither of them can deny, and they sneak off to a secluded garden to celebrate their love for each other. Passions burn hot in this Hindi audio story and the sparks fly between the couple as they celebrate their love for each other in a romantic garden paradise. Indulge in this sexy intimate audio story in Hindi and feel the Holi heat.",
    "rank":1
  },
  "fire-ice":{
    "subtitle":"Romantic Bondage Audio Story:",
    "text":"In this audio porn story written by The Secret Submissive, Ani and her Dominant/boyfriend Josef explore intimate pleasures with candles and ice cubes. Ani has always hated celebrating her birthday; the fuss…the attention…all eyes on her - but this year, Josef is pulling out all the stops to give the two of them an intimate evening to remember. Tonight, Ani will be the center of his universe and he’s more than ready to swathe her in mind-blowing pleasure as she has never experienced before. Explore the sensual evening Josef has planned in this romantic audio story.",
    "rank":2
  },
  "spanked-by-sir":{
    "subtitle":"Submissive Audio Audio Story:",
    "text":"In this audio porn story written by The Secret Submissive, Melissa vies for her boyfriend’s attention while he’s getting some work done in his home office. With a month off from her job as a university professor, Marissa is desperate to get some playtime with her Sir. Indulge in this sweet Dominance and submission audio sex story.",
    "rank":3
  },
}


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "headline": "The Secret Submissive",
  "image": [
    "https://audiodesires.com/images/secret-submissive/erotic-audio-stories-by-secret-submissive-1-1",
    "https://audiodesires.com/images/secret-submissive/erotic-audio-stories-by-secret-submissive-4-3.jpg",
    "https://audiodesires.com/images/secret-submissive/erotic-audio-stories-by-secret-submissive-16-9.jpg"
   ],
  "datePublished": "2021-12-04",
  "dateModified": "2021-12-04",
  "isAccessibleForFree":true,
  "author": {
    "@type": "Person",
    "name": "Jaimee Bell"
  },
   "publisher": {
    "@type": "Organization",
    "name": "Audiodesires",
    "logo": {
      "@type": "ImageObject",
      "url": "https://audiodesires.com/images/logos/audiodesires_black.png"
    }
  },
  "mainEntityOfPage":{
    "@type":"WebPage",
    "@id":"https://audiodesires.com/secret-submissive/"
 }
}

const SecretSubmissive = (props) => {
  const {user} = useContext(FirebaseContext);
  const lang = props.pageContext.lang

  const convertArrayToObject = (array, key, content) => {
    const initialValue = [];
    return array.reduce((obj, item) => {
      return [...obj,{...content[item.en[key]],...item}]
    }, initialValue);
  };
  const Stories =  convertArrayToObject(props.data.allStory.nodes, 'slug', content)

  return <>
  <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
  <ArticleWrapper>
    <Seo 
      title={"The Secret Submissive Stories"}
      description={"Erotic audio stories written by The Secret Submissive ✅ Professional audio erotica ✅ Different stories and fantasies ✅ 5-20 min ✅ Free stories available ✅ Respectful & relatable"}
      structuredData={[articleSchema]}
    />
    <ContentPaddingWrapper>
      
      <MainHeading>{articleSchema.headline}</MainHeading>
      <SubHeadline>Indulge in your deepest desires with these stories written by British Indian erotic author The Secret Submissive and produced by Audiodesires.</SubHeadline>
      <MetaData>Last Updated on April 12, 2021 </MetaData>
      <MetaData>by {articleSchema.author.name} </MetaData>
      <ArticleHeaderImage src={headerImage} alt=" The Secret Submissive Audiodesires"/>
      <FirstText>
        The Secret Submissive is a British Indian author of honest and immersive erotic fiction. Audiodesires has been proud to work with The Secret Submissive, turning several of her spicy stories into erotic audio stories on our platform. 
        <br/><br/>
        The Secret Submissive is passionate about communicating the importance of trust, equality, and consent within a relationship and is a strong advocate for the telling of healthy Dom/sub relationships within relatable, honest BDSM-centric stories. 
        <br/><br/>
        English-school teacher by day, erotic writer by night, The Secret Submissive has written several stories for us (which you can find below) that we have been proud to produce and release in collaboration with her. 
        <br/><br/>
        Indulge in your naughtiest desires with these sensual, kinky stories from The Secret Submissive and Audiodesires. You can follow The Secret Submissive on <a href ='https://twitter.com/TSubmissive' target='_blank' rel="noreferrer">Twitter</a> or buy some of her other kinky stories through her <a href ='https://thesecretsubmissive.com/' target='_blank' rel="noreferrer">website</a>.  
      </FirstText>

      {Stories.sort((a,b) =>  b.rank - a.rank ).map((story) => (
        <ListItemWrapper key={story.rank}>
          <ItemImageWrapper>
            <Link to={`/story/${story.en.slug}`}>
              <GatsbyImage
                image={story.localImage.childImageSharp.gatsbyImageData}
                alt={`${story.en.title} - Erotic Audio Story by Audiodesires`} />
            </Link>
          </ItemImageWrapper>
          <ItemTextColumn>
            <ItemHeadline><EroticAudio>{story.subtitle}</EroticAudio>{story.en.title}</ItemHeadline>
            <ItemMetaInfo>Narrator: <StyledLink to={`/voice/${story[lang].voices[0].name.toLowerCase()}`}>{story[lang].voices[0].name}</StyledLink></ItemMetaInfo>
            <ItemText dangerouslySetInnerHTML={{ __html: content[story.en.slug].text}}/>
            <ItemCta>Listen to <StyledLink to={`/story/${story.en.slug}`}>{story.en.title}</StyledLink>{} now.</ItemCta>
          </ItemTextColumn>
        </ListItemWrapper>
      
      ))}
    </ContentPaddingWrapper>
  </ArticleWrapper>
</>;
}

export default SecretSubmissive
export const query = graphql`{
  allStory(
    filter: {en: {slug: {in: ["holi-heat", "fire-ice", "spanked-by-sir"]}}}
  ) {
    nodes {
      ...seoBlogPostStoryInfo 
    } 
  }
}
`